<template>
  <DxDataGrid
    id="fba-inventory-grid"
    ref="gridView"
    class="sxr-grid"
    :class="{ 'filter-panel-expanded': expandFilters }"
    width="100%"
    height="100%"
    :data-source="dataSource"
    :show-borders="false"
    :show-row-lines="true"
    :show-column-lines="true"
    :remote-operations="true"
    :word-wrap-enabled="true"
    :no-data-text="noResultsFoundMessage"
    :column-auto-width="true"
    :allow-column-reordering="true"
    :allow-column-resizing="!isCurrentBreakPointXs"
    column-resizing-mode="widget"
    @exporting="onExporting"
    @exported="onExported"
    @cellPrepared="onCellPrepared"
  >
    <DxToolbar>
      <DxItem
        location="before"
        template="filterPanel"
      />
      <DxItem
        name="columnChooserButton"
      />
      <DxItem
        name="exportButton"
      />
    </DxToolbar>
    <DxHeaderFilter
      :visible="false"
      :hide-select-all-on-search="false"
    />
    <DxEditing
      :allow-updating="true"
      mode="cell"
    />
    <DxPaging :page-size="25" />
    <DxColumnChooser
      :enabled="true"
      mode="select"
    />
    <DxScrolling
      mode="virtual"
    />
    <DxStateStoring
      :enabled="true"
      type="localStorage"
      storage-key="DI_GRID_STATE"
    />
    <DxExport
      :enabled="true"
      :formats="['csv', 'xlsx']"
      :texts="{
        exportAll: 'Export to {0}'
      }"
    />
    <DxColumn
      :allow-editing="false"
      :show-in-column-chooser="false"
      :allow-hiding="false"
      data-field="asin"
      caption="ASIN"
      data-type="string"
      :fixed="true"
      :allow-resizing="false"
      css-class="fixed-column-cell"
      fixed-position="left"
      :min-width="140"
      cell-template="asinCell"
    />
    <DxColumn
      :allow-editing="false"
      :show-in-column-chooser="false"
      data-field="marketplace.marketplace_id"
      caption="Marketplace"
      data-type="string"
      :visible="false"
      :min-width="100"
    />
    <DxColumn
      :allow-editing="false"
      :show-in-column-chooser="false"
      :allow-exporting="false"
      :allow-sorting="false"
      data-field="marketplace"
      caption=""
      data-type="string"
      :fixed="true"
      :allow-resizing="false"
      css-class="fixed-column-cell"
      fixed-position="left"
      :min-width="50"
      alignment="center"
      cell-template="marketplaceCell"
    />
    <DxColumn
      v-if="!isCurrentBreakPointXs"
      :allow-editing="false"
      :show-in-column-chooser="false"
      :allow-hiding="false"
      data-field="sku"
      caption="SKU"
      data-type="string"
      :fixed="true"
      css-class="fixed-column-cell"
      fixed-position="left"
      :min-width="150"
      :visible="true"
    />
    <DxColumn
      v-if="!isCurrentBreakPointXs"
      :allow-editing="false"
      data-field="product_name"
      caption="Product Name"
      data-type="string"
      sort-order="asc"
      :fixed="true"
      css-class="fixed-column-cell"
      fixed-position="left"
      :min-width="400"
    />
    <DxColumn
      data-field="brand"
      caption="Brand"
      data-type="string"
      css-class="fixed-column-cell"
      :fixed="true"
      fixed-position="left"
      :allow-resizing="false"
      :min-width="140"
    />
    <DxColumn
      data-field="supplier"
      caption="Supplier"
      data-type="string"
      css-class="fixed-column-cell"
      :fixed="true"
      fixed-position="left"
      :allow-resizing="false"
      :min-width="140"
    />
    <DxColumn
      data-field="tags"
      caption="Tags"
      data-type="string"
      css-class="fixed-column-cell"
      :fixed="true"
      fixed-position="left"
      :allow-resizing="false"
      :min-width="140"
      cell-template="tagsCell"
      edit-cell-template="tagBoxEditor"
    />

    <DxColumn
      caption="Sales"
    >
      <DxColumn
        :allow-editing="false"
        data-field="sales.last_3_days.display"
        caption="3 Days"
        data-type="string"
        :min-width="80"
        alignment="right"
      />

      <DxColumn
        :allow-editing="false"
        data-field="sales.last_7_days.display"
        caption="7 Days"
        data-type="string"
        :min-width="80"
        alignment="right"
      />

      <DxColumn
        :allow-editing="false"
        data-field="sales.last_14_days.display"
        caption="14 Days"
        data-type="string"
        :min-width="80"
        alignment="right"
      />

      <DxColumn
        :allow-editing="false"
        data-field="sales.last_30_days.display"
        caption="30 Days"
        data-type="string"
        :min-width="80"
        alignment="right"
      />

      <DxColumn
        :allow-editing="false"
        data-field="sales.last_90_days.display"
        caption="90 Days"
        data-type="string"
        :min-width="80"
        alignment="right"
      />
    </DxColumn>

    <DxColumn
      caption="Inventory"
      css-class="cell-highlighted"
    >
      <DxColumn
        :allow-editing="false"
        data-field="sellable_quantity.display"
        caption="FBA Inventory"
        data-type="string"
        :min-width="150"
        alignment="right"
      />

      <DxColumn
        :allow-editing="false"
        data-field="inbound_shipped_quantity.display"
        caption="inbound"
        data-type="string"
        :min-width="150"
        alignment="right"
      />
    </DxColumn>

    <DxColumn caption="Pacing vs 30 days">
      <DxColumn
        :allow-editing="false"
        data-field="pacing.vs_30_days.days_3.display"
        caption="3 VS 30"
        data-type="string"
        :min-width="100"
        alignment="right"
      />
      <DxColumn
        :allow-editing="false"
        data-field="pacing.vs_30_days.days_7.display"
        caption="7 VS 30"
        data-type="string"
        :min-width="100"
        alignment="right"
      />
      <DxColumn
        :allow-editing="false"
        data-field="pacing.vs_30_days.days_14.display"
        caption="14 VS 30"
        data-type="string"
        :min-width="100"
        alignment="right"
      />
    </DxColumn>

    <DxColumn caption="Pacing vs 90 days">
      <DxColumn
        :allow-editing="false"
        data-field="pacing.vs_90_days.days_3.display"
        caption="3 VS 90"
        data-type="string"
        :min-width="100"
        alignment="right"
      />
      <DxColumn
        :allow-editing="false"
        data-field="pacing.vs_90_days.days_7.display"
        caption="7 VS 90"
        data-type="string"
        :min-width="100"
        alignment="right"
      />
      <DxColumn
        :allow-editing="false"
        data-field="pacing.vs_90_days.days_14.display"
        caption="14 VS 90"
        data-type="string"
        :min-width="100"
        alignment="right"
      />
      <DxColumn
        :allow-editing="false"
        data-field="pacing.vs_90_days.days_30.display"
        caption="30 VS 90"
        data-type="string"
        :min-width="100"
        alignment="right"
      />
    </DxColumn>

    <DxColumn caption="Inventory Needed">
      <DxColumn
        :allow-editing="false"
        data-field="need_for_14_days.display"
        caption="14 Days"
        data-type="string"
        :min-width="100"
        alignment="right"
      />
      <DxColumn
        :allow-editing="false"
        data-field="need_for_30_days.display"
        caption="30 Days"
        data-type="string"
        :min-width="100"
        alignment="right"
      />
      <DxColumn
        :allow-editing="false"
        data-field="need_for_45_days.display"
        caption="45 Days"
        data-type="string"
        :min-width="100"
        alignment="right"
      />
      <DxColumn
        :allow-editing="false"
        data-field="need_for_90_days.display"
        caption="90 Days"
        data-type="string"
        :min-width="100"
        alignment="right"
      />
    </DxColumn>

    <DxColumn
      caption="# of Sales"
    >
      <DxColumn
        :allow-editing="false"
        caption="Days Left"
        data-field="days_left.display"
        data-type="string"
        :min-width="120"
        alignment="right"
      />
    </DxColumn>
    <template #marketplaceCell="{ data }">
      <CountryFlag
        :key="data.data.marketplace.country_code"
        :country="data.data.marketplace.country_code"
        :title="data.data.marketplace.country"
        :alt="data.data.marketplace.country"
        :size="isCurrentBreakPointXs ? 'small' : undefined"
      />
    </template>
    <template #tagsCell="{ data }">
      <span v-if="data.value">
        <BBadge
          v-for="tag in data.value"
          :key="tag"
          pill
          variant="light-primary"
        >{{ tag }}
        </BBadge>
      </span>
    </template>
    <template #filterPanel>
      <Filters
        :expand-filters="expandFilters"
        :dx-data-grid-instance="$refs.gridView.instance"
        :applied-filters="filters"
        @on-show-filter-click="onShowFilterClick"
        @on-filter-changed="onFilterChanged"
      />
    </template>
    <template #tagBoxEditor="{ data: cellInfo }">
      <TagsTagBoxComponent
        :value="cellInfo.data.tags"
        :on-value-changed="(value) => onValueChanged(value, cellInfo)"
        :data-source="cellInfo.data.tags"
        :data-grid-component="cellInfo.component"
      />
    </template>
    <template #asinCell="{ data }">
      <div
        v-if="data.data.has_multiple_sku"
        class="tw-flex tw-flex-col"
      >
        <feather-icon
          v-b-tooltip.hover="'ASIN has multiple SKUs'"
          class="tw-text-yellow-500 tw-absolute tw-top-1 tw-right-1 tw-w-4 tw-h-4 tw-cursor-pointer"
          icon="HelpCircleIcon"
        />
        <span>{{ data.data.asin }}</span>
        <span class="tw-text-xs tw-text-yellow-500">{{ data.data.sku }}</span>
      </div>
      <span v-else>{{ data.data.asin }}</span>
    </template>
  </DxDataGrid>
</template>

<script>
import { VBTooltip, BBadge } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import {
  DxColumn,
  DxColumnChooser,
  DxDataGrid,
  DxHeaderFilter,
  DxItem,
  DxPaging,
  DxScrolling, DxStateStoring,
  DxToolbar,
  DxExport,
  DxEditing,
} from 'devextreme-vue/data-grid'
import CountryFlag from 'vue-country-flag'
import { Workbook } from 'exceljs'
import { saveAs } from 'file-saver-es'
import { exportDataGrid } from 'devextreme/excel_exporter'

import FBAInventoryDataSource from '@/data/fba-inventory.datasource'
import Filters from '@/views/fba-inventory/components/Filters.vue'
import TagsTagBoxComponent from '@/views/fba-inventory/components/TagsTagBoxComponent.vue'

export default {
  name: 'FBAInventory',
  components: {
    Filters,
    DxDataGrid,
    DxColumn,
    DxPaging,
    DxHeaderFilter,
    DxColumnChooser,
    DxToolbar,
    DxItem,
    DxScrolling,
    DxStateStoring,
    DxExport,
    DxEditing,
    CountryFlag,
    BBadge,
    TagsTagBoxComponent,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  data() {
    return {
      noResultsFoundMessage: 'No Result Found',
      totalCount: 0,
      gridFilterValue: null,
      expandFilters: false,
      filters: {
        marketplaces: [],
        search: null,
        builder: null,
      },
    }
  },
  computed: {
    currentBreakPoint() {
      return this.$store.getters['app/currentBreakPoint']
    },
    isCurrentBreakPointXs() {
      return this.currentBreakPoint === 'xs'
    },
    filterParams() {
      return {
        marketplaces: this.filters.marketplaces,
        search: this.filters.search,
        filter: this.filters.builder,
      }
    },
    dataSource() {
      return FBAInventoryDataSource.getInstance(this.$store, this.filterParams, this.totalCount, this.onDataLoaded)
    },
  },
  methods: {
    onDataLoaded({ totalCount }) {
      this.totalCount = totalCount
    },
    onFilterChanged({ marketplaces, search, builder }) {
      this.filters.marketplaces = marketplaces
      this.filters.search = search
      this.filters.builder = builder
    },
    onCellPrepared(e) {
      if (e.rowType === 'data' && e.column.name === 'asin' && e.key.has_multiple_sku) {
        e.cellElement.classList.add('cell-highlighted')
      }
    },
    onExported(e) {
      e.component.columnOption('marketplace.marketplace_id', 'visible', false)
      e.component.endUpdate()
    },
    onExporting(e) {
      e.component.beginUpdate()
      e.component.columnOption('marketplace.marketplace_id', 'visible', true)

      const workbook = new Workbook()
      const worksheet = workbook.addWorksheet('Inventory Xray')

      exportDataGrid({
        component: e.component,
        worksheet,
        autoFilterEnabled: true,
        customizeCell: this.customizeCellOnExporting,
      }).then(() => {
        const date = new Date()
        if (e.format === 'csv') {
          workbook.csv.writeBuffer().then(buffer => {
            saveAs(new Blob([buffer], { type: 'application/octet-stream' }), `Inventory Xray ${date.toLocaleDateString('en-US')}.csv`)
          })
        } else {
          workbook.xlsx.writeBuffer().then(buffer => {
            saveAs(new Blob([buffer], { type: 'application/octet-stream' }), `Inventory Xray ${date.toLocaleDateString('en-US')}.xlsx`)
          })
        }
      })
      e.cancel = true
    },
    customizeCellOnExporting({ gridCell, excelCell }) {
      if (gridCell.rowType === 'header' && gridCell.column.dataField && gridCell.column.dataField.includes('pacing')) {
        // eslint-disable-next-line no-param-reassign
        excelCell.value = `${excelCell.value} %`
      }
      if (gridCell.rowType === 'data' && gridCell.column.dataField && gridCell.column.dataField.includes('pacing')) {
        // eslint-disable-next-line no-param-reassign
        excelCell.value = parseFloat(excelCell.value.replace('%', ''))
      }
    },
    onValueChanged(value, cellInfo) {
      cellInfo.setValue(value)
      cellInfo.component.updateDimensions()
    },
    onShowFilterClick(value) {
      this.expandFilters = value
    },
  },
}
</script>

<style lang="scss">
@import '@/assets/scss/common/grid.scss';
.dark-layout .dx-popup-wrapper > .dx-overlay-content {
  border: 1px solid #404656;
  background: #353d55;
}
</style>

<style lang="scss" scoped>
  #fba-inventory-grid {
    min-height: calc(100dvh - 120px);
    max-height: calc(100dvh - 120px);

    //fixed-column-cell cell-highlighted
    ::v-deep .fixed-column-cell {
      &.cell-highlighted {
        border-left: 2px solid #dc9215;
        position: relative;
      }
    }
  }
</style>
