<template>
  <div class="toolbar-filter-panel">
    <template v-if="isCurrentBreakPointXs">
      <div class="tw-flex tw-flex-col tw-gap-3">
        <div class="tw-flex tw-gap-2">
          <DxTextBox
            v-model="filters.search"
            class="tw-flex-1"
            placeholder="Enter ASIN, SKU or keyword"
            :on-key-up="onFilterChange"
            value-change-event="keyup"
          />
          <DxButton
            icon="filter"
            type="default"
            width="35"
            height="35"
            :styling-mode="expandFilters ? 'contained' : 'outlined'"
            @click="onSearchButtonClick"
          />
        </div>
        <template v-show="expandFilters">
          <DxTagBox
            v-model="filters.marketplaces"
            :data-source="marketplaces"
            :search-enabled="true"
            display-expr="title"
            value-expr="id"
            placeholder="All Marketplaces"
            item-template="item"
            :on-item-click="onFilterChange"
          >
            <template #item="{ data }">
              <div class="d-flex flex-row justify-content-between align-items-center">
                <div class="flex-1">
                  {{ data.title }}
                </div>
                <div>
                  <CountryFlag :country="data.country.toLowerCase()" />
                </div>
              </div>
            </template>
          </DxTagBox>
          <DxButton
            id="filter-builder-button"
            icon="filter"
            type="default"
            :text="isCurrentBreakPointXs ? 'Advanced filters' : ''"
            :styling-mode="isFilterApplied"
            :width="!isCurrentBreakPointXs ? 36 : null"
            @click="onFilterBuilderButtonClick"
          />
        </template>
      </div></template>
    <template v-if="!isCurrentBreakPointXs">
      <DxTextBox
        v-model="filters.search"
        placeholder="Enter ASIN, SKU or keyword"
        :on-key-up="onFilterChange"
        value-change-event="keyup"
      />
      <DxTagBox
        v-model="filters.marketplaces"
        :data-source="marketplaces"
        :search-enabled="true"
        display-expr="title"
        value-expr="id"
        placeholder="All Marketplaces"
        item-template="item"
        :on-item-click="onFilterChange"
      >
        <template #item="{ data }">
          <div class="d-flex flex-row justify-content-between align-items-center">
            <div class="flex-1">
              {{ data.title }}
            </div>
            <div>
              <CountryFlag :country="data.country.toLowerCase()" />
            </div>
          </div>
        </template>
      </DxTagBox>
      <DxButton
        id="filter-builder-button"
        icon="filter"
        type="default"
        :styling-mode="isFilterApplied"
        width="36"
        @click="onFilterBuilderButtonClick"
      />
    </template>
    <DxPopup
      id="filter-builder"
      :width="popupWidth"
      :height="popupHeight"
      :visible.sync="isFilterBuilderVisible"
      :show-title="false"
      :hide-on-outside-click="true"
    >
      <DxPosition
        of="#filter-builder-button"
        :at="popupPosition.at"
        :my="popupPosition.my"
        :offset="popupPosition.offset"
      />
      <DxToolbarItem
        widget="dxButton"
        toolbar="bottom"
        location="before"
        :options="templatesBtnOptions"
      />
      <DxToolbarItem
        widget="dxButton"
        toolbar="bottom"
        location="after"
        :options="clearFiltersButtonOptions"
      />
      <DxToolbarItem
        widget="dxButton"
        toolbar="bottom"
        location="after"
        :options="applyFiltersButtonOptions"
      />
      <template #content>
        <DxScrollView
          width="100%"
          style="max-height: 500px"
        >
          <DxFilterBuilder
            ref="dxFilterBuilder"
            :value.sync="filters.builder"
            :fields="filterBuilderFields"
            :group-operations="['and', 'or']"
            :max-group-level="1"
            :on-value-changed="onFilterBuilderValueChange"
          />
        </DxScrollView>
      </template>
    </DxPopup>
    <DxPopover
      :width="300"
      :visible.sync="isPopoverVisible"
      :wrapper-attr="{ id: 'filter-template' }"
      target="#filter-templates-btn"
      position="bottom left"
    >
      <DxPosition
        of="#filter-templates-btn"
        :at="popoverPosition.at"
        :my="popoverPosition.my"
      />
      <MyFilters
        :data-source="filterTemplates"
        @on-filter-template-click="onFilterTemplateClick"
        @on-filter-template-delete="onFilterTemplateDelete"
      />
      <DxToolbarItem
        widget="dxButton"
        toolbar="bottom"
        location="before"
        :options="saveFilterBtnOptions"
      />
    </DxPopover>
    <DxPopup
      :wrapper-attr="{ id: 'save-filter-template' }"
      :width="savingFilterPopupWidth"
      :height="200"
      :visible.sync="isSavingFilterPopupVisible"
      :show-title="true"
      shading-color="rgb(34 41 47 / 50%)"
      title="Save Personal Filter"
      :hide-on-outside-click="false"
    >
      <DxToolbarItem
        widget="dxButton"
        toolbar="bottom"
        location="after"
        :options="cancelFilterSavingBtnOptions"
      />
      <DxToolbarItem
        widget="dxButton"
        toolbar="bottom"
        location="after"
        :options="submitFilterSavingBtnOptions"
      />
      <template #content>
        <div class="d-flex flex-column">
          <DxTextBox
            v-model="filterName"
            placeholder="Name Your filter"
          >
            <DxValidator ref="filterNameTextBoxValidator">
              <DxRequiredRule message="Filter Name is required" />
            </DxValidator>
          </DxTextBox>
          <div class="text-danger">
            {{ savingFilterTemplateError }}
          </div>
        </div>
      </template>
    </DxPopup>
  </div>
</template>

<script>
import DxTagBox from 'devextreme-vue/tag-box'
import CountryFlag from 'vue-country-flag'
import DxTextBox from 'devextreme-vue/text-box'
import { DxPopup, DxPosition, DxToolbarItem } from 'devextreme-vue/popup'
import { DxScrollView } from 'devextreme-vue/scroll-view'
import DxButton from 'devextreme-vue/button'
import DxFilterBuilder from 'devextreme-vue/filter-builder'
import { DxPopover } from 'devextreme-vue/popover'
import {
  DxValidator,
  DxRequiredRule,
} from 'devextreme-vue/validator'
import { fields as filterBuilderFields } from '@/views/fba-inventory/filters/conf'
import MyFilters from '@/views/fba-inventory/components/MyFilters.vue'

export default {
  name: 'Filters',
  components: {
    MyFilters,
    DxTagBox,
    DxTextBox,
    CountryFlag,
    DxPopup,
    DxScrollView,
    DxPosition,
    DxButton,
    DxFilterBuilder,
    DxToolbarItem,
    DxPopover,
    DxValidator,
    DxRequiredRule,
  },
  props: {
    dxDataGridInstance: {
      type: Object,
      required: true,
    },
    appliedFilters: {
      type: Object,
      default: () => ({}),
    },
    expandFilters: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isPopoverVisible: false,
      filters: {
        marketplaces: [],
        search: null,
        builder: null,
      },
      debounce: {
        delay: 800,
        timer: undefined,
      },
      isFilterBuilderVisible: false,
      filterBuilderFields,
      applyFiltersButtonOptions: {
        text: 'Apply',
        onClick: this.onFilterApplyClick,
        cssClass: 'dx-btn-xs',
        stylingMode: 'contained',
        elementAttr: {
          class: 'dx-btn-xs',
        },
      },
      clearFiltersButtonOptions: {
        text: 'Clear',
        onClick: this.onFilterClearClick,
        cssClass: 'dx-btn-xs',
        stylingMode: 'contained',
        elementAttr: {
          class: 'dx-btn-xs',
        },
      },
      templatesBtnOptions: {
        text: 'Templates',
        onClick: this.onTemplatesBtnClick,
        stylingMode: 'contained',
        elementAttr: {
          id: 'filter-templates-btn',
          class: 'dx-btn-xs',
        },
      },
      saveFilterBtnOptions: {
        text: 'Save Active Filters',
        icon: 'save',
        stylingMode: 'text',
        onClick: this.onSaveTemplateBtnClick,
        elementAttr: {
          class: 'dx-btn-xs',
        },
      },
      isSavingFilterPopupVisible: false,

      filterName: null,
      filterTemplates: [],
      cancelFilterSavingBtnOptions: {
        text: 'Cancel',
        stylingMode: 'outlined',
        onClick: this.onCancelSavingFilterTemplateBtnClick,
      },
      submitFilterSavingBtnOptions: {
        text: 'Save Filter',
        stylingMode: 'contained',
        onClick: this.onSubmitFilterTemplateBtnClick,
      },
      savingFilterTemplateError: null,
    }
  },
  computed: {
    currentBreakPoint() {
      return this.$store.getters['app/currentBreakPoint']
    },
    isCurrentBreakPointXs() {
      return this.currentBreakPoint === 'xs'
    },
    isCurrentBreakPointMd() {
      return this.currentBreakPoint === 'md'
    },
    marketplaces() {
      return this.$store.getters['app/availableMarketplaceOptions']
    },
    popupWidth() {
      if (this.isCurrentBreakPointXs) {
        return '99vw'
      }

      return 560
    },
    popupHeight() {
      if (this.isCurrentBreakPointXs) {
        return '40vh'
      }
      return 'auto'
    },
    savingFilterPopupWidth() {
      if (this.isCurrentBreakPointXs) {
        return '90vw'
      }
      return 300
    },
    popupPosition() {
      if (this.isCurrentBreakPointMd) {
        return {
          at: 'center bottom',
          my: 'center top',
        }
      }
      if (this.isCurrentBreakPointXs) {
        return {
          at: 'left bottom',
          my: 'left top',
          offset: '-25 0',
        }
      }
      return {
        at: 'right bottom',
        my: 'right top',
        offset: undefined,
      }
    },
    popoverPosition() {
      return {
        at: 'left bottom',
        my: 'left top',
      }
    },
    isFilterApplied() {
      return this.filters.builder ? 'contained' : 'outlined'
    },
  },
  mounted() {
    this.filters = JSON.parse(JSON.stringify(this.appliedFilters))
    this.$store.dispatch('user/getSettings', 'daily-inventory-filters').then(data => {
      this.filterTemplates = data.items ?? []
    }).catch(() => {
      this.filterTemplates = []
    })
  },
  methods: {
    onFilterChange() {
      if (this.debounce.timer) {
        clearTimeout(this.debounce.timer)
      }
      this.debounce.timer = setTimeout(() => {
        this.$emit('on-filter-changed', this.filters)
      }, this.debounce.delay)
    },
    onFilterBuilderButtonClick() {
      this.isFilterBuilderVisible = !this.isFilterBuilderVisible
    },
    onFilterBuilderValueChange({ value }) {
      this.filters.builder = value
    },
    onFilterApplyClick() {
      this.isFilterBuilderVisible = false
      this.$emit('on-filter-changed', this.filters)
    },
    onFilterClearClick() {
      this.$refs.dxFilterBuilder.instance.option('value', null)
      this.$emit('on-filter-changed', this.filters)
    },
    onTemplatesBtnClick() {
      this.isPopoverVisible = true
    },
    onSaveTemplateBtnClick() {
      if (this.filters.builder) {
        this.isPopoverVisible = false
        this.isFilterBuilderVisible = false
        this.isSavingFilterPopupVisible = true
      }
    },
    onCancelSavingFilterTemplateBtnClick() {
      this.savingFilterTemplateError = null
      this.isSavingFilterPopupVisible = false
    },
    onFilterTemplateClick({ filter }) {
      this.isPopoverVisible = false
      this.filters.builder = filter
      this.$emit('on-filter-changed', this.filters)
    },
    onFilterTemplateDelete({ itemIndex }) {
      this.filterTemplates.splice(itemIndex, 1)
      const payload = {
        index: 'daily-inventory-filters',
        payload: { items: this.filterTemplates },
      }
      this.$store.dispatch('user/updateSettings', payload).then(() => {
        this.isSavingFilterPopupVisible = false
      }).catch(() => {
        this.savingFilterTemplateError = 'Server error. Please try later.'
      })
    },
    onSubmitFilterTemplateBtnClick() {
      if (this.$refs.filterNameTextBoxValidator.instance.validate().isValid) {
        this.filterTemplates.push({
          title: this.filterName,
          filter: this.filters.builder,
        })
        const payload = {
          index: 'daily-inventory-filters',
          payload: { items: this.filterTemplates },
        }
        this.$store.dispatch('user/updateSettings', payload).then(() => {
          this.isSavingFilterPopupVisible = false
        }).catch(() => {
          this.savingFilterTemplateError = 'Server error. Please try later.'
        })
      }
    },
    onSearchButtonClick() {
      this.$emit('on-show-filter-click', !this.expandFilters)
    },
  },
}
</script>

<style lang="scss" scoped>
  .toolbar-filter-panel {
    min-width: 100%;
    max-width: 100%;
    display: grid;
    flex: 1 1 auto;
    grid-gap: 10px;
    grid-template-columns: repeat(auto-fill, 100%);

    @media (min-width: 576px) {
      grid-template-columns: repeat(auto-fill, 31.622%);
    }

    .dx-texteditor {
      width: auto;
    }
  }
</style>
