import DataSource from 'devextreme/data/data_source'
import CustomStore from 'devextreme/data/custom_store'

export default class {
  static getInstance(store, params, totalCount, onLoaded) {
    return new DataSource({
      store: new CustomStore({
        load: options => {
          const payload = { ...params }
          payload.take = options.take
          payload.skip = options.skip ?? 0

          if (options.sort && options.sort.length) {
            const { selector } = options.sort[0]
            const sortMapping = {
              asin: 'asin',
              product_name: 'productName',
              marketplace: 'marketplace',
              'sales.last_3_days.display': 'salesLast3Days',
              'sales.last_7_days.display': 'salesLast7Days',
              'sales.last_14_days.display': 'salesLast14Days',
              'sales.last_30_days.display': 'salesLast30Days',
              'sales.last_90_days.display': 'salesLast90Days',
              'inbound_shipped_quantity.display': 'inboundShippedQuantity',
              'sellable_quantity.display': 'sellableQuantity',
              'pacing.vs_30_days.days_3.display': 'pacing3VS30',
              'pacing.vs_30_days.days_7.display': 'pacing7VS30',
              'pacing.vs_30_days.days_14.display': 'pacing14VS30',
              'pacing.vs_90_days.days_3.display': 'pacing3VS90',
              'pacing.vs_90_days.days_7.display': 'pacing7VS90',
              'pacing.vs_90_days.days_14.display': 'pacing14VS90',
              'pacing.vs_90_days.days_30.display': 'pacing30VS90',
              'need_for_14_days.display': 'needFor14Days',
              'need_for_30_days.display': 'needFor30Days',
              'need_for_45_days.display': 'needFor45Days',
              'need_for_90_days.display': 'needFor90Days',
              'days_left.display': 'daysLeft',
            }
            payload.sort_by = sortMapping[selector] ?? 'asin'
            payload.sort_direction = options.sort[0].desc ? 'DESC' : 'ASC'
          }

          if (options.isLoadingAll) {
            payload.take = totalCount
            payload.skip = 0
          }
          return store.dispatch('inventory/fetchDailyInventory', payload).then(result => {
            if (!result.items) {
              return []
            }

            onLoaded({ totalCount: result.total_items })
            return {
              data: result.items,
              totalCount: result.total_items,
            }
          })
        },
        update: (key, values) => {
          const requestPayload = {
            asin: key.asin,
            sku: key.sku,
            marketplace_id: key.marketplace.marketplace_id,
            key: Object.keys(values)[0],
            value: Object.values(values)[0],
          }

          return store.dispatch('inventory/updateAdditionalData', requestPayload).then(result => result)
        },
      }),
      paginate: true,
      pageSize: 25,
    })
  }
}
