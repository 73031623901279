<template>
  <DxList
    height="100%"
    :data-source="dataSource"
    :search-enabled="true"
    search-mode="contains"
    :allow-item-deleting="true"
    item-delete-mode="static"
    no-data-text="No filter templates yet"
    display-expr="title"
    search-expr="title"
    value-expr="filter"
    :focus-state-enabled="false"
    @item-click="onItemClick"
    @item-deleted="onItemDelete"
  />
</template>

<script>
import { DxList } from 'devextreme-vue/list'

export default {
  name: 'MyFilters',
  components: {
    DxList,
  },
  props: {
    dataSource: {
      type: Array,
      default: () => ([]),
    },
  },
  methods: {
    onContentReady(e) {
      if (!e.element.querySelector('.filter-list-label')) {
        const searchEl = e.element.querySelector('.dx-list-search')
        const textBox = document.createElement('div')
        textBox.innerText = 'My Filters'
        textBox.classList.add('filter-list-label')
        searchEl.after(textBox)
      }
    },
    onItemClick({ itemData }) {
      this.$emit('on-filter-template-click', itemData)
    },
    onItemDelete({ itemIndex, itemData }) {
      this.$emit('on-filter-template-delete', { itemIndex, itemData })
    },
  },
}
</script>

<style scoped>

</style>
