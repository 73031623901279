const filterOperations = {
  string: ['contains', 'startswith', 'endswith', '=', '<>'],
  tags: ['contains', 'startswith', 'endswith'],
  numeric: ['=', '<>', '<', '>', '<=', '>=', 'between'],
}
// eslint-disable-next-line import/prefer-default-export
export const fields = [
  {
    caption: 'Supplier',
    width: 50,
    dataField: 'supplier',
    dataType: 'string',
    filterOperations: filterOperations.string,
  },
  {
    caption: 'Brand',
    width: 50,
    dataField: 'brand',
    dataType: 'string',
    filterOperations: filterOperations.string,
  },
  {
    caption: 'Tags',
    width: 50,
    dataField: 'tags',
    dataType: 'string',
    filterOperations: filterOperations.tags,
  },
  {
    caption: 'ASIN',
    width: 50,
    dataField: 'asin',
    dataType: 'string',
    filterOperations: filterOperations.string,
  },
  {
    caption: 'SKU',
    width: 50,
    dataField: 'sku',
    dataType: 'string',
    filterOperations: filterOperations.string,
  },
  {
    caption: 'Product Name',
    width: 50,
    dataField: 'product_name',
    dataType: 'string',
    filterOperations: filterOperations.string,
  },
  {
    caption: 'Sales 3 Days',
    width: 50,
    dataField: 'sales_3_days',
    dataType: 'numeric',
    filterOperations: filterOperations.numeric,
  },
  {
    caption: 'Sales 7 Days',
    width: 50,
    dataField: 'sales_7_days',
    dataType: 'numeric',
    filterOperations: filterOperations.numeric,
  },
  {
    caption: 'Sales 14 Days',
    width: 50,
    dataField: 'sales_14_days',
    dataType: 'numeric',
    filterOperations: filterOperations.numeric,
  },
  {
    caption: 'Sales 30 Days',
    width: 50,
    dataField: 'sales_30_days',
    dataType: 'numeric',
    filterOperations: filterOperations.numeric,
  },
  {
    caption: 'Sales 90 Days',
    width: 50,
    dataField: 'sales_90_days',
    dataType: 'numeric',
    filterOperations: filterOperations.numeric,
  },
  {
    caption: 'FBA Inventory',
    width: 50,
    dataField: 'sellableQuantity',
    dataType: 'numeric',
    filterOperations: filterOperations.numeric,
  },
  {
    caption: 'Inbound',
    width: 50,
    dataField: 'inboundShippedQuantity',
    dataType: 'numeric',
    filterOperations: filterOperations.numeric,
  },
  {
    caption: 'Pacing 3 VS 30',
    width: 50,
    dataField: 'pacing3VS30',
    dataType: 'numeric',
    filterOperations: filterOperations.numeric,
  },
  {
    caption: 'Pacing 7 VS 30',
    width: 50,
    dataField: 'pacing7VS30',
    dataType: 'numeric',
    filterOperations: filterOperations.numeric,
  },
  {
    caption: 'Pacing 14 VS 30',
    width: 50,
    dataField: 'pacing14VS30',
    dataType: 'numeric',
    filterOperations: filterOperations.numeric,
  },
  {
    caption: 'Pacing 3 VS 90',
    width: 50,
    dataField: 'pacing3VS90',
    dataType: 'numeric',
    filterOperations: filterOperations.numeric,
  },
  {
    caption: 'Pacing 7 VS 90',
    width: 50,
    dataField: 'pacing7VS90',
    dataType: 'numeric',
    filterOperations: filterOperations.numeric,
  },
  {
    caption: 'Pacing 14 VS 90',
    width: 50,
    dataField: 'pacing14VS90',
    dataType: 'numeric',
    filterOperations: filterOperations.numeric,
  },
  {
    caption: 'Pacing 30 VS 90',
    width: 50,
    dataField: 'pacing30VS90',
    dataType: 'numeric',
    filterOperations: filterOperations.numeric,
  },
  {
    caption: 'Days Left',
    width: 50,
    dataField: 'daysLeft',
    dataType: 'numeric',
    filterOperations: filterOperations.numeric,
  },
  {
    caption: 'Needed For 14 Days',
    width: 50,
    dataField: 'needFor14Days',
    dataType: 'numeric',
    filterOperations: filterOperations.numeric,
  },
  {
    caption: 'Needed For 30 Days',
    width: 50,
    dataField: 'needFor30Days',
    dataType: 'numeric',
    filterOperations: filterOperations.numeric,
  },
  {
    caption: 'Needed For 45 Days',
    width: 50,
    dataField: 'needFor45Days',
    dataType: 'numeric',
    filterOperations: filterOperations.numeric,
  },
  {
    caption: 'Needed For 90 Days',
    width: 20,
    dataField: 'needFor90Days',
    dataType: 'numeric',
    filterOperations: filterOperations.numeric,
  },
]
