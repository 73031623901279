<template>
  <DxTagBox
    v-model="currentValue"
    :data-source="options"
    :show-selection-controls="true"
    :max-displayed-tags="3"
    :show-multi-tag-only="false"
    :on-value-changed="(e) => onValueChanged(e.value)"
    :on-selection-changed="onSelectionChanged"
    :accept-custom-value="true"
    @customItemCreating="onCustomItemCreating"
  />
</template>

<script>
import DxTagBox from 'devextreme-vue/tag-box'

export default {
  name: 'TagsTagBoxComponent',
  components: { DxTagBox },
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    onValueChanged: {
      type: Function,
      default: () => ({}),
    },
    dataSource: {
      type: Array,
      default: () => ([]),
    },
    dataGridComponent: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      currentValue: this.value,
      options: JSON.parse(JSON.stringify(this.dataSource)),
    }
  },
  methods: {
    onSelectionChanged() {
      this.dataGridComponent.updateDimensions()
    },
    onCustomItemCreating(args) {
      const newValue = args.text
      // eslint-disable-next-line no-param-reassign
      args.customItem = newValue
      this.options.push(newValue)
    },
  },
}
</script>

<style scoped>

</style>
